import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-image-field',
  template: `<a target="_blank" [href]="to.link"><div [class]="to.imageClass"></div></a>`,
  standalone: false,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class KendoImageField extends FieldType<FieldTypeConfig> {}
