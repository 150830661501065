import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'kendo-separator-wrapper',
  template: `
    <ng-container #fieldComponent></ng-container>
    <span class="k-form-separator"></span>
  `,
  standalone: false,
})
export class KendoSeparatorWrapperComponent extends FieldWrapper {}
