import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface LetContext<T> {
  let: T | null;
}

@Directive({
  selector: '[let]',
  standalone: false,
})
export class LetDirective<T> {
  private context: LetContext<T> = { let: null };

  @Input()
  set let(value: T) {
    this.context.let = value;
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<LetContext<T>>,
  ) {
    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }
}
