import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { KKinkOption } from '@app/states/case/kkink/kkink-case.model';
import { Observable, Subscription, isObservable } from 'rxjs';

@Pipe({
  name: 'kkinkOptions',
  standalone: false,
})
export class KKinkOptionsPipe implements PipeTransform, OnDestroy {
  private result: KKinkOption[] = [];
  private subscription?: Subscription;
  private source$?: Observable<any[]>;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.source$ = undefined;
  }

  public transform(options: Observable<any[]> | any[] | undefined): KKinkOption[] {
    if (isObservable(options)) {
      if (this.source$ !== options) {
        this.subscription?.unsubscribe();
        this.subscription = options.subscribe((newValue) => {
          this.result = newValue;
          this.cdr.markForCheck();
        });
        this.source$ = options;
      }
    } else {
      this.result = options ?? [];
    }
    return this.result;
  }
}
