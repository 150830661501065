import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'kendo-info-icon-field',
  template: `
    <div class="info-icon" #anchor>
      <i class="ruv-icon ruv-icon-tiny ruv-info-filled" (click)="toggle()" (keyup)="toggle()" tabindex="0"></i>
    </div>
    <kendo-popup
      #popup
      [positionMode]="'absolute'"
      [anchorAlign]="{ horizontal: 'center', vertical: 'bottom' }"
      [popupAlign]="{ horizontal: 'center', vertical: 'top' }"
      [anchor]="anchor"
      (anchorViewportLeave)="show = false"
      *ngIf="show"
    >
      <div class="content">
        <p class="info--text" [safeHTML]="props.text | translate"></p>
      </div>
    </kendo-popup>
  `,
  styles: [
    `
      .info-icon {
        cursor: pointer;
      }
      .ruv-icon {
        padding-left: 5px;
        padding-bottom: 5px;
        color: var(--primary);
      }
      .content {
        padding: 0.5rem;
        color: var(--primary);
      }
    `,
  ],
  standalone: false,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class KendoInfoIconField extends FieldType<FieldTypeConfig> {
  public show = false;

  @ViewChild('anchor', { read: ElementRef })
  public anchor!: ElementRef;
  @ViewChild('popup', { read: ElementRef })
  public popup!: ElementRef;

  public toggle(): void {
    this.show = !this.show;
  }

  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.show = false;
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target!)) {
      this.show = false;
    }
  }

  private contains(target: EventTarget): boolean {
    return (
      this.anchor.nativeElement.contains(target) || (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }
}
