import { Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@shared/string-util';

@Pipe({
  name: 'isEmpty',
  standalone: false,
})
export class IsEmpty implements PipeTransform {
  transform(text: string | undefined) {
    return StringUtil.isEmpty(text);
  }
}
